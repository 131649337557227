.section {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius);

    @media (min-width: 300px) {
        padding-right: var(--gap);
        padding-left: var(--gap);
    }

    @media (min-width: 900px) {
        padding-right: calc(2 * var(--gap));
        padding-left: calc(2 * var(--gap));
    }
}
