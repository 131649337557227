@keyframes line-in {
    0% {
        opacity: 0;
        transform: translateX(50vw) scaleX(12);
    }

    1% {
        opacity: 1;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.heading {
    margin: 0 auto var(--gap);
    position: relative;
    opacity: 0;
    color: var(--content-heading);
    font-size: 2em;
    text-align: center;

    &::first-letter {
        color: var(--content-heading-admit);
    }

    &::before {
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -10px;
        left: 0;
        content: '';
        background: var(--navigation-color);

        @media (prefers-reduced-motion: no-preference) {
            opacity: 0;
            transform-origin: left center;
            animation: line-in 0.2s var(--ease-out-quart) 1.1s forwards;
        }
    }

    @media (min-width: 900px) {
        font-size: 1.8em;
    }

    @media (min-width: 1200px) {
        font-size: 2em;
    }

    @media (min-width: 1500px) {
        font-size: 2.4em;
    }
}
