.wrapper {
    width: 100%;
    position: relative;
}

.computer {
    width: 100%;
    padding-bottom: 56.25%;
    position: absolute;
    z-index: 1;
}

.display {
    width: 90%;
    height: calc(95% + 1px);
    position: absolute;
    left: 5%;
    border-radius: 18px 18px 12px 12px;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #ccc;
}

.header {
    width: 100%;
    height: 18px;
    position: absolute;
    z-index: 2;
    background: #121212;
    border-radius: 18px 18px 0 0;
}

.camera {
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #121212;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid #323232;
}

.content {
    width: 100%;
    height: calc(100% - 24px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 18px;
    z-index: 2;
    overflow: hidden;
    border-radius: 0 0 12px 12px;
    border-width: 0 12px 18px;
    border-style: solid;
    border-color: #121212;

    video {
        max-width: 100%;
        height: auto;
    }
}

.footer {
    width: 100%;
    height: 24px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background: hsl(var(--color-dark-gray));
    border-radius: 0 0 12px 12px;
}

.keyboard,
.backside {
    width: 100%;
    position: absolute;
}

.keyboard {
    height: 3%;
    bottom: 2%;
    background: #ccc;
    border-radius: 2px 2px 0 0;

    &::before {
        width: 25%;
        height: 50%;
        position: absolute;
        left: 50%;
        content: '';
        background: #aaa;
        transform: translateX(-50%);
        border-radius: 0 0 8px 8px;
    }
}

.backside {
    height: 2%;
    bottom: 0;
    background: #aaa;
    border-radius: 0 0 20px 20px;
}
