.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: var(--zindex-header);

    @media (min-width: 300px) {
        padding: var(--gap);
    }

    @media (min-width: 900px) {
        padding: var(--gap) calc(2 * var(--gap)) 0;
    }
}

.link {
    text-decoration: none;
}

.disabled {
    pointer-events: none;
}
