.wrapper {
    width: 100%;
    position: relative;
}

.mobile {
    width: 100%;
    padding-bottom: 198.65%;
    position: absolute;
    z-index: 1;
    box-shadow: 0 0.9px 3.7px rgba(0, 0, 0, 0.026), 0 2.2px 8.6px rgba(0, 0, 0, 0.037), 0 3.9px 15.5px rgba(0, 0, 0, 0.046), 0 6.4px 25.7px rgba(0, 0, 0, 0.054), 0 10.6px 42.3px rgba(0, 0, 0, 0.063), 0 18.5px 74px rgba(0, 0, 0, 0.074), 0 40px 160px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 3px solid #121212;
}

.content {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 2;
    overflow: hidden;
    border-radius: 12px;

    video {
        max-width: 100%;
        height: auto;
    }
}

.header {
    width: 55%;
    height: 14px;
    position: absolute;
    top: -1px;
    left: 50%;
    z-index: 3;
    background: #121212;
    transform: translateX(-50%);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    &::before,
    &::after {
        width: 16px;
        height: 8px;
        position: absolute;
        top: 0;
        content: '';
        background: transparent;
    }

    &::before {
        left: -16px;
        box-shadow: 8px 0 0 #121212;
        border-top-right-radius: 8px;
    }

    &::after {
        right: -16px;
        box-shadow: -8px 0 0 #121212;
        border-top-left-radius: 8px;
    }
}

.speaker {
    width: 25%;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: hsl(var(--color-dark-gray));
    transform: translate(-50%, -50%);
    border-radius: 3px;
}

.camera {
    width: 3px;
    height: 3px;
    position: absolute;
    top: 50%;
    right: 25%;
    background: hsl(var(--color-dark-gray));
    transform: translateY(-50%);
    border-radius: 50%;
}

.button {
    width: 50%;
    height: 3px;
    position: absolute;
    bottom: 8px;
    left: 3px;
    z-index: 3;
    background: #121212;
    border-radius: 6px;
}

.controlMute,
.controlSound,
.controlTrigger {
    width: 1px;
    position: absolute;
    background: #121212;
}

.controlMute {
    height: 4%;
    top: 12%;
    left: -4px;
}

.controlSound {
    height: 7%;
    top: 20%;
    left: -4px;

    + .controlSound {
        top: 29%;
    }
}

.controlTrigger {
    height: 12%;
    top: 22%;
    right: -4px;
}
