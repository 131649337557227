.keys {
    display: none;

    @media (min-width: 1200px) {
        margin-left: auto;
        display: flex;
        flex-direction: column;
    }
}

.key {
    margin: 2px 4px;
    display: flex;
    align-self: center;
}
