.wrapper {
    position: relative;
}

.logo {
    width: 100%;
    max-height: 30px;
    transition: max-height var(--transition-duration) var(--transition-timing);

    &.active {
        --navigation-color: var(--navigation-color-active);
    }

    @media (min-width: 600px) {
        max-height: 40px;
    }

    @media (min-width: 900px) {
        max-height: 50px;
    }

    @media (min-width: 1200px) {
        max-height: 60px;
    }
}

.shadow {
    --velocity: 100;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 27.5%);
    z-index: -1;
    opacity: 0;
    transform: translate3d(calc((-1 * var(--cursor-x) + 50vw) / var(--velocity)), calc((-1 * var(--cursor-y) + 50vh) / var(--velocity)), 0);
    will-change: transform;
    transition: var(--float-transition);
    border-radius: var(--border-radius);
    border-right: 20px solid transparent;
    border-bottom: 30px solid var(--curtain-color);
    border-left: 20px solid transparent;

    @media (min-width: 900px) {
        top: calc(50% - 25px);
        left: calc(50% - 12.5%);
        border-right: 25px solid transparent;
        border-bottom: 40px solid var(--curtain-color);
        border-left: 25px solid transparent;
    }
}
