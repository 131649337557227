.main {
    width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-template: max-content auto max-content / 100%;
    grid-template-areas: 'header'
        'section'
        'footer';
    position: relative;
    overflow: hidden;
    background: var(--section-background, hsl(var(--color-blue-lighten-5)));
    border: calc(var(--gap) / 2) solid hsl(var(--color-white));

    noscript {
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 600px) {
        border-width: var(--gap);
    }
}
