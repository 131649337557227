.root {
    --section-background: hsl(var(--color-blue-lighten-5));
    --selection-background: hsl(var(--color-blue));
    --curtain-color: hsl(var(--color-blue-lighten-4));
    --content-heading: hsl(var(--color-blue));
    --content-heading-admit: hsl(var(--color-blue-lighten-1));
    --key-background: hsl(var(--color-blue-lighten-3));
}

.article {
    width: 100%;
    max-width: 75vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

    @media (min-width: 900px) {
        max-width: 50vw;
    }
}

.paragraph {
    margin: calc(3 * var(--gap)) 0 auto;
    color: var(--navigation-color);
    font-size: 1em;
    line-height: 1.75;

    strong {
        position: relative;
        color: var(--content-heading);
        border-bottom: 1px dotted var(--navigation-color);
    }

    span {
        font-size: 0.8em;
        text-align: right;
        font-style: italic;
    }

    @media (min-width: 900px) {
        font-size: 1.2em;
    }

    @media (min-width: 1200px) {
        font-size: 1.3em;
    }

    @media (min-width: 1500px) {
        font-size: 1.4em;
    }
}

.devicesWrapper {
    width: 100vw;
    max-width: 1280px;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 10vw;
    z-index: 0;

    @media (max-height: 800px) {
        display: none;
    }
}

.mobile,
.tablet,
.computer {
    width: 100%;
    position: absolute;
}

.mobile {
    max-width: 120px;
    right: 28%;
    bottom: 40vh;
    z-index: 3;
}

.tablet {
    max-width: 280px;
    right: 10%;
    bottom: 54vh;
    z-index: 2;
}

.computer {
    max-width: 720px;
    right: 25%;
    bottom: 58vh;
    z-index: 1;
}
