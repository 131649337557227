.wrapper {
    width: 100%;
    position: relative;
}

.tablet {
    width: 100%;
    padding-bottom: 125%;
    position: absolute;
    z-index: 1;
    box-shadow: 0 0.9px 3.7px rgba(0, 0, 0, 0.026), 0 2.2px 8.6px rgba(0, 0, 0, 0.037), 0 3.9px 15.5px rgba(0, 0, 0, 0.046), 0 6.4px 25.7px rgba(0, 0, 0, 0.054), 0 10.6px 42.3px rgba(0, 0, 0, 0.063), 0 18.5px 74px rgba(0, 0, 0, 0.074), 0 40px 160px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    border: 12px solid #121212;
}

.content {
    width: calc(100% + 2px);
    height: calc(100% - 44px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 22px;
    left: 0;
    z-index: 2;
    overflow: hidden;

    video {
        max-width: 100%;
        height: auto;
    }
}

.header,
.footer {
    width: calc(100% + 2px);
    height: 24px;
    position: absolute;
    left: -1px;
    z-index: 2;
    background: #121212;
}

.header {
    top: -1px;
}

.footer {
    bottom: -1px;
}

.camera {
    width: 4px;
    height: 4px;
    position: absolute;
    top: calc(50% - 6px);
    left: 50%;
    background: hsl(var(--color-dark-gray));
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.button {
    width: 22px;
    height: 22px;
    position: absolute;
    top: calc(50% - 6px);
    left: 50%;
    z-index: 3;
    background: #121212;
    border-radius: 50%;
    border: 1px solid hsl(var(--color-dark-gray));
}
